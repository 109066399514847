body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.headline-gradient {
  background: -webkit-linear-gradient(left, rgb(76, 0, 255), rgb(0, 140, 255));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 15px;
}